// extracted by mini-css-extract-plugin
export var ArtistDescription = "JohnFekner-module--ArtistDescription--GC5a4";
export var ArtistInfos = "JohnFekner-module--ArtistInfos--pUN-T";
export var ButtonWrapper = "JohnFekner-module--ButtonWrapper --4ARMs";
export var CardWrapper = "JohnFekner-module--CardWrapper--ayd8S";
export var CarrouselWrapper2 = "JohnFekner-module--CarrouselWrapper2--ImufR";
export var Citations = "JohnFekner-module--Citations--YLt-W";
export var DescriptionWrapper = "JohnFekner-module--DescriptionWrapper--SS3EN";
export var ImageWrapper = "JohnFekner-module--ImageWrapper--2sK11";
export var LinkWrapper = "JohnFekner-module--LinkWrapper--nxmGY";
export var MobileProtrait = "JohnFekner-module--MobileProtrait---K6Cs";
export var More = "JohnFekner-module--More--M3hbO";
export var MoreButton = "JohnFekner-module--MoreButton--2LcNy";
export var NameWrapper = "JohnFekner-module--NameWrapper--tHL9Y";
export var PdpWrapper = "JohnFekner-module--PdpWrapper---gIW-";
export var PhotosWrapper = "JohnFekner-module--PhotosWrapper--TV03x";
export var ProfilWrapper = "JohnFekner-module--ProfilWrapper--xTLYn";
export var TitleWrapper = "JohnFekner-module--TitleWrapper--z5XLg";
export var Wrapper = "JohnFekner-module--Wrapper--o0P4V";